import Cookies from "js-cookie";
import { getCookie } from "./Utils";

const headers = {
  authToken: Cookies.get("authToken", undefined),
  "Content-Type": "application/json",
};

export const fetchCloudSearchApi = async (route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export const fetchAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export const postRequestData = async (data, route) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    const Response = await response.json();

    if (Response.type !== "success") {
      throw new Error("Failed to fetch data From api-services");
    }

    return Response;
  } catch (error) {
    return error.message;
  }
};

export async function getAllNotificationHandler({ limit = 10, offset = 0, recent = 10, view_all = 1 }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-notifications`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ limit, offset, recent }),
  });

  if (!response.ok) {
    const errorData = await response.json();

    const error = new Error("An Error occurred while getting all notifications.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function setNotificationCountHandler({ notification_id: notification_id = "0", status = "1" }) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/set-notification-status`, {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ notification_id, status }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    const error = new Error("An Error occurred while setting notification count.");
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }
  if (response.ok) {
    console.log(response);
  }

  return await response.json();
}
