export const qrcode_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-qr.png`;
export const silo_marketplace_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-marketplace.png`;
export const silotalk_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-talk.png`;
export const storage_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-storage.png`;
export const streamdeck_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-streamdeck.png`;
export const support_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-support.png`;
export const tv_logo = `${process.env.REACT_APP_API_URL}logo/apps/tv-logo.png`;
export const homelogo = `${process.env.REACT_APP_API_URL}logo/apps/home-logo.png`;
export const silogame = `${process.env.REACT_APP_API_URL}logo/apps/silo-games.png`;
export const store_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-store.png`;
export const silotalk_login = require("./silotalk_login.png");
export const edit = require("./edit.png");
export const bell = require("./white_theme/bell_white.png");
export const calender = require("./white_theme/calender_white.png");
export const Homelightlogo = require("./white_theme/home_white.png");
export const silo_talk_app = require("./white_theme/silo_talk_app_w.png");
export const silo_talk_video = require("./white_theme/silo_talk_video_white.png");
export const silo_talk_audio = require("./white_theme/silo_talk_audio_white.png");
export const silo_talk_end_call = require("./white_theme/cut_cl_white.png");
export const shutdown = require("./white_theme/shutdown_white.png");
export const plus = require("./white_theme/plus_white.png");
export const profile = require("./white_theme/dp_white.png");
export const mikelogo_light = require("../mike_w.png");
export const cloud_mic = require("../cloud_mike.png");

export const img = `${process.env.REACT_APP_API_URL}logo/apps/background-image.png`;
export const calender_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-calender.png`;
export const mail_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-mail.png`;
export const community_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-community.png`;
export const assembler_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-assembler.png`;
export const pesonapost_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-post.png`;
export const pesonadigest_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-digest.png`;
export const pesonaos_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-os.png`;
export const pesonaradio_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-radio.png`;
export const erp_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-erp.png`;
export const suite_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-suite.png`;
export const constructor_logo = `${process.env.REACT_APP_API_URL}logo/apps/constructor-tool.png`;
export const canvas_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-canvas.png`;
export const map_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-maps.png`;
export const wallet_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-wallet.png`;
export const coin_exchange_logo = `${process.env.REACT_APP_API_URL}logo/apps/coin_exchange.png`;
export const merchant_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-merchant-white.png`;
export const merchant_logo_d = `${process.env.REACT_APP_API_URL}logo/apps/silo-merchant-dark.png`;
export const blockchain_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-blockchain.png`;
export const bank_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-bank.png`;
export const sitebuilder_logo = `${process.env.REACT_APP_API_URL}logo/apps/site-builder.png`;
export const marketplace_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-marketplace.png`;
export const d_viewer_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-3d-viewer.png`;
export const silo_publisher_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-publisher.png`;

// pov over images in footer for white mode

export const pop_silogame = require("./white_theme/game_white.png");
export const pop_qrcode_logo = require("./white_theme/qr_white.png");
export const pop_silo_marketplace_logo = require("./white_theme/mktplace_white.png");
export const pop_storage_logo = require("./white_theme/storage_white.png");
export const pop_silosecure_data = require("./white_theme/securedata_white.png");
export const pop_streamdeck_logo = require("./white_theme/streamdeck_white.png");
export const w_store_logo = require("./white_theme/store.png");
export const pop_tv_logo = require("./white_theme/tv_white.png");
export const pop_silotalk_logo = require("./white_theme/silotalk_regular.png");
export const background_light = require("./white_theme/new_bg_white.png");
export const searchpage_logo = require("./white_theme/silocloud_white.png");
export const searchpage_logo_head = require("./white_theme/silocloud_white_head.png");

// Images for dark mode for header and searchpage

export const cloud_search = require("../search_cloud.png");
export const web_search = require("../web_search.png");
export const web_search_w = require("../web_search_w.png");
export const d_calendar = require("./black_theme/calendar.png");
export const d_bell = require("./black_theme/bell.png");
export const d_apps_logo = require("./black_theme/app.png");
export const d_searchpage_logo = require("./black_theme/silocloud_black.png");
export const d_searchpage_logo_head = require("./black_theme/silocloud_black_head.png");

// Popover images for darkmode

export const d_streamdeck_logo = require("./black_theme/streamdeck.png");
export const d_store_logo = require("./black_theme/store.png");
export const d_tv_logo = require("./black_theme/tv_black.png");
export const d_silo_talk = require("./black_theme/silotalk_black.png");
export const d_game_logo = require("./black_theme/game_black.png");
export const d_qr_logo = require("./black_theme/qr_black.png");
export const d_marketplace_logo = require("./black_theme/mktplace.png");
export const d_storage_logo = require("./black_theme/storage_black.png");
export const d_secure_data = require("./black_theme/securedata_black.png");
export const d_plus_logo = require("./black_theme/plus_black.png");
export const pen_logo = require("./black_theme/Pen.png");
export const d_home_logo = require("./black_theme/home_black.png");
export const mikelogo_dark = require("../mike_b.png");

// right bottom corner images

export const d_silo_talk_app = require("./black_theme/silo_talk_app.png");
export const d_silo_talk_video = require("./black_theme/silo_talk_video.png");
export const d_silo_talk_audio = require("./black_theme/silo_talk_audio.png");
export const d_silo_talk_end_call = require("./black_theme/silotalk_end_call.png");
