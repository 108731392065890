import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";
import SimpleBar from "simplebar-react";

//^ stylesheets
import styles from "../../../../assets/scss/_themes-vars.module.scss";
import styleVar from "../../../../assets/scss/_themes-vars.module.scss";
// import "./notification.scss";

//^ http request
import { getAllNotificationHandler, setNotificationCountHandler } from "../../../../utils/api_helper";
//^ icons

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { useThemeUpdate } from "../../../provider/Theme";

import { shortenString } from "../../../../utils/Utils";
// import { TooltipComponent } from "../../../../components/Component";
import NotificationSkeletonLoader from "./NotificationSkeletonLoader";
import { Tooltip } from "@mui/material";

function NotificationItem({ title, time, isSender, link, updatedAt, recent, index, notification, imgLink }) {

  const handleModuleNotifications = (notification) => {
    switch (notification.module) {
      case "1":
        if (notification?.link != "#") {
          window.location.href = `${"https://accounts.silocloud.io"}${notification?.link}`;
        } else window.location.href = "https://accounts.silocloud.io";
        break;
      case "2":
        if (notification?.link != "#") {
          window.location.href = `${"https://qr.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://qr.silocloud.io";
        break;
      case "3":
        if (notification?.link != "#") {
          window.location.href = `${"https://support.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://support.silocloud.io";
        break;
      case "4":
        if (notification?.link != "#") {
          window.location.href = `${"https://streamdeck.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://streamdeck.silocloud.io";
        break;
      case "5":
        if (notification?.link != "#") {
          window.location.href = `${"https://store.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://store.silocloud.io";
        break;
      case "6":
        if (notification?.link != "#") {
          window.location.href = `${"https://calendar.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://calendar.silocloud.io";
        break;
      case "7":
        if (notification?.link != "#") {
          window.location.href = `${notification?.link} `;
        } else window.location.href = "https://storage.silocloud.io";
        break;
      case "8":
        if (notification?.link != "#") {
          window.location.href = `${"https://wallet.silocloud.io"} `;
        } else window.location.href = "https://wallet.silocloud.io";
        break;
      case "9":
        window.location.href = "https://mail.silocloud.io";
        break;
      case "10":
        window.location.href = "https://game.silocloud.io";
        break;
      case "11":
        window.location.href = "https://talk.silocloud.io";
        break;
      case "12":
        window.location.href = "https://tv.silocloud.io";
        break;
      case "13":
        if (notification?.link != "#") {
          window.location.href = `${"https://coin.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://coin.silocloud.io";
        break;
      case "13":
        if (notification?.link != "#") {
          window.location.href = `${"https://coin.silocloud.io/"}${notification?.link} `;
        } else window.location.href = "https://coin.silocloud.io";
        break;
      case "14":
        if (notification?.link != "#" && notification?.link != null) {
          window.location.href = `${"https://admin.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://admin.silocloud.io";
        break;
      case "15":
        if (notification?.link != "#") {
          window.location.href = `${"https://3d.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://3d.silocloud.io";
        break;
      case "16":
        if (notification?.link != "#") {
          window.location.href = `${"https://publisher.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://publisher.silocloud.io";
        break;
      case "17":
        window.location.href = "https://site.silocloud.com";
        break;
      case "18":
        if (notification?.link != "#") {
          window.location.href = `${"https://community.silocloud.io"}${notification?.link} `;
        } else window.location.href = "https://community.silocloud.io";
        break;
      case "19":
        if (notification?.link != "#") {
          window.location.href = `${notification?.link} `;
        } else window.location.href = "https://connect.silocloud.io";
        break;
      default:
        console.log(`Not Available!...`);
        break;
    }
  };

  return (
    <>

      <li
        className="p-4 web-app pointer"
        onClick={() => {
          // setNotificationCountHandler({ notification_id: notification.reference_id, status: 1 });
          handleModuleNotifications(notification);
        }}
      >
        <div className="d-flex justify-content-between align-items-start w-100">
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex justify-content-center align-items-center"
              style={{ height: "28px", width: "28px" }}>
              {/* <Icon
                name={isSender ? "curve-down-right" : "curve-down-left"}
                className={[`icon - circle ${ isSender ? "bg-primary-dim" : "bg-success-dim" } `]}
              /> */}
              <img src={`${"https://api.silocloud.io/"}${imgLink} `} alt="" className="" />
            </div>
            <div className="nk-notification-content">
              <div
                className="nk-notification-text"
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {/* {title.length > 27 ? (
                  <TooltipComponent
                    type="text"
                    content={shortenString(title, 27)}
                    direction="top"
                    id={`notification - ${ index } `}
                    text={title}
                  />
                ) : (
                  title
                )} */}
                <Tooltip key={index} title={title} arrow placement="top">
                  {shortenString(title, 27)}
                </Tooltip>
              </div>
              <div className="nk-notification-time">{time}</div>
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "600" }}>{moment(updatedAt).format("h:mm A")}</div>
          </div>
        </div>
      </li>
    </>
  );
}

const Notification = () => {
  const themeUpdate = useThemeUpdate();

  const [open, setOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({ notifications: [] });
  const [limit, setLimit] = useState(10);
  const [notificationIds, setNotificationIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getAllNotificationHandler({ limit: limit });
      if (response.status) {
        setTotalCount(response?.data?.total_count);
        const unReadNotifications = Array.isArray(response.data.notifications)
          ? response.data.notifications.filter((notification) => notification.seen === "0")
          : [];
        setNotificationCount(unReadNotifications.length || 0);

        const newNotifications = response.data.notifications.filter((notification) => {
          return !notificationData.notifications.some(
            (existingNotification) => existingNotification.id === notification.id
          );
        });

        setNotificationData((prevNotification) => ({
          notifications: [...prevNotification.notifications, ...newNotifications],
        }));

        const notificationsId = response.data.notifications.map((notification) => notification.id);
        setNotificationIds(notificationsId);
      }
    } catch (error) {
      setError(error);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [limit]);

  const markAllRead = async () => {
    try {
      const data = await setNotificationCountHandler({ notification_id: 0, status: 1 });
      if (!data.status) {
        toast.error(data?.message);
        // if (data?.redirect) {
        //   window.location.href = `${ process.env.REACT_APP_ACCOUNT_LOGIN_URL } `;
        // }
      } else {
        toast.success(data?.message);
        setNotificationCount(0);
        // setNotificationData((prevData) => ({
        //   notifications: prevData.notifications.map((notification) => ({
        //     ...notification,
        //     seen: "1",
        //   })),
        // }));
        setNotificationData({ notifications: [] });
        // toggleFetch(loading);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadMoreNotifications = async () => {
    setLimit((prevLimit) => prevLimit + 10);
  };

  return (
    <Dropdown
      // onMouseEnter={readUnreadNotificationsHandler}
      isOpen={open}
      toggle={toggle}
      className="user-dropdown"
    >
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-na">
          <Icon name="bell-fill" className="text-white" />
          {notificationCount > 0 && (
            <div color="primary" pill className="countIcon">
              {loading ? 0 : notificationCount}
            </div>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head d-flex justify-content-between align-items-center">
          <div>{typeof data.title === "string" ? data.title : "Title Error"}</div>
          {
            loading ? <Spinner size={"sm"} color="primary" style={{ borderWidth: "1px" }} />
              : (
                <div
                  size="small"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (notificationCount <= 0) {
                      toast.error("There are no unread messages.");
                    } else {
                      markAllRead({ notification_id: "0", status: "1" });
                    }
                  }} >
                  Mark All as Read
                </div>
              )
          }
        </div>

        <div className="dropdown-body">
          <div className="nk-notification dropdownMenu">
            {loading ? (
              <NotificationSkeletonLoader />
            ) : error ? (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: styleVar.errorMain,
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
              >
                {error.message}
              </div>
            ) : notificationData.notifications.length > 0 ? (
              <SimpleBar>
                {notificationData.notifications.map((notification, index) => (
                  <NotificationItem
                    key={index}
                    index={index}
                    title={typeof notification.title === "string" ? notification.title : "Title Error"}
                    time={moment(notification.created_at).format("DD MMM")}
                    isSender={notification.is_sender}
                    link={notification.link}
                    updatedAt={notification.updated_at}
                    recent={notification.recent}
                    notification={notification}
                    imgLink={notification.imgSrc}
                  />
                ))}
                {
                  totalCount >= limit ?
                    (
                      <div className="dropdown-foot center">
                        {
                          loading ? (
                            <Spinner size="sm" style={{ borderWidth: "1px", color: "inherit" }} />
                          ) : (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={loadMoreNotifications}
                              disabled={loading}
                            >
                              {"View More"}
                            </div>
                          )
                        }
                      </div>
                    ) : ""
                }
              </SimpleBar>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ padding: "1.25rem", color: styles.primaryMain }}>No Notification Found</div>
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
