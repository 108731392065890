import React, { createContext, useEffect, useRef, useState } from "react";
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom";

import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import { useTheme } from "./provider/Theme";
import "../pages/css/app.css";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { muiAction } from "../store/slices/mui-slice";
import defaultBgImage from "../images/applogos/black_theme/background-img.jpg";
import defaultBgImageWhite from "../images/applogos/white_theme/bg_white.jpeg";
import ImageWhite from "../images/applogos/white_theme/silocloud_white.png";
import ImageBlack from "../images/applogos/black_theme/silocloud_black.png";
import { fetchCloudSearchApi } from "../utils/api_helper";
import { getCookie } from "../utils/Utils";
import { io } from "socket.io-client";

export const GlobalContext = createContext();

const Layout = ({ title, app, ...props }) => {
  const dispatch = useDispatch();
  const muiTheme = useMuiTheme();
  const navigation = useNavigation();
  const isPageLoading = navigation.state === "loading";

  const [files, setFiles] = useState([]);
  const theme = useTheme();
  const { skin } = theme;

  const [bg, setBg] = useState(defaultBgImage);
  const [color, setColor] = useState("#aabbcc");
  const [selectedOption, setSelectedOption] = useState("fit");
  const [backgroundMode, setBackgroundMode] = useState("image");
  const [newsData, setNewsData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(localStorage.getItem("selectedLogo") || ImageBlack);
  const [issearchdiv, setIsSearchDiv] = useState(true);
  const [selectedLogoImage, setSelectedLogoImage] = useState(localStorage.getItem("selectedLogo") || ImageBlack);
  const [gotNotification, setGotNotification] = useState(0);
  // useEffect(() => {
  //   setBg(defaultBgImage);
  // }, [skin]);

  const setBackground = (image) => {
    setBg(image);
    // localStorage.setItem("BackgroundNk-image", image);
    setBackgroundMode("image");
  };

  const backgroundSetImage = {
    backgroundImage: `url(${bg})`,
    backgroundColor: color,
  };

  const [isSearchNewsVisible, setIsSearchNewsVisible] = useState(false);
  const searchNewsRef = useRef(null);

  const onTrendingClick = async () => {
    setIsSearchDiv(!issearchdiv);
    setIsSearchNewsVisible(!isSearchNewsVisible);
    const result = await fetchCloudSearchApi("api/v1/public/get-public-news");

    if (result.status) {
      const tempNews = result?.data?.news;
      setNewsData(result?.data?.news);
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchNewsRef.current &&
      !searchNewsRef.current.contains(event.target) &&
      !event.target.classList.contains("trending-mode")
    ) {
      setIsSearchNewsVisible(false);
    }
  };
  useEffect(() => {
    if (isSearchNewsVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchNewsVisible]);

  // const [isScrolled, setIsScrolled] = useState(false);

  // const handleScroll = () => {
  //   const mainbody = document.getElementsByClassName("nk-wrap")[0];
  //   if (mainbody && mainbody.scrollTop > 180) {
  //     setIsScrolled(true);
  //     setIsSearchDiv(false);
  //   } else {
  //     setIsScrolled(false);
  //     setIsSearchDiv(true);
  //   }
  // };

  // useEffect(() => {
  //   const mainbody = document.getElementsByClassName("nk-wrap")[0];
  //   if (mainbody) {
  //     mainbody.addEventListener("scroll", handleScroll);
  //     return () => {
  //       mainbody.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, []);

  const username = getCookie("username");
  const moduleName = process.env.REACT_APP_MODULE_NAME;
  const socket = io("https://live.silocloud.io");

  useEffect(() => {
    socket.on(`notification_${moduleName}_${username}`, (data) => {
      // console.log("Notification received:", data);
      // console.log(data);
      if (data) {
        setGotNotification((prev) => prev + 1);
        if ("Notification" in window) {
          if (Notification.permission === "granted") {
            new Notification(`Notification: ${data.title}`, {
              body: `${data.body}`,
              icon: "https://api.silocloud.io/logo/apps/silo-mail.png",
            });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
              if (permission === "granted") {
                new Notification(`Notification: ${data.title}`, {
                  body: `${data.body}`,
                  icon: "https://api.silocloud.io/logo/apps/silo-mail.png",
                });
              }
            });
          }
        } else {
          console.log("Browser does not support notifications.");
        }
      }
    });

    return () => {
      socket.off(`notification_${moduleName}_${username}`);
    };
  }, []);

  const handleImageChange = (image) => {
    setSelectedImage(image);
    setSelectedLogoImage(image);
    localStorage.setItem("selectedLogo", image);
  };

  return (
    <>
      <ScrollRestoration
        getKey={(location, _matches) => {
          // default behavior
          return location.key;
        }}
      />
      <GlobalContext.Provider
        value={{
          ImageWhite,
          ImageBlack,
          files,
          setFiles,
          setBackground,
          bg,
          setBg,
          color,
          setColor,
          selectedOption,
          setSelectedOption,
          backgroundMode,
          setBackgroundMode,
          isSearchNewsVisible,
          onTrendingClick,
          searchNewsRef,
          // isScrolled,
          // setIsScrolled,
          issearchdiv,
          newsData,
          setNewsData,
          selectedImage,
          setSelectedImage,
          handleImageChange,
          selectedLogoImage,
          setSelectedLogoImage,
        }}
      >
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps apps-only">
          <AppMain>
            <AppWrap className={skin === "dark" ? "dark-img" : "light-img"} style={backgroundSetImage}>
              <Header fixed />
              <ToastContainer hideProgressBar autoClose={1200} />
              {isPageLoading && <div>Loading</div>}
              <Outlet />
              <Footer fixed />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GlobalContext.Provider>
    </>
  );
};

export default Layout;
