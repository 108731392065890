import siloCoin from "../../images/applogos/coin-d.png";
// console.log(process.env.REACT_APP_PROJECT_OLD_LINK_NAME);
export const linkItems = [
  {
    link: `${process.env.REACT_APP_PROJECT_OLD_LINK_NAME}enumblockchain-explorer`,
    img: siloCoin,
    text: "BlockChain",
    oldsite: true,
  },
  {
    link: `${process.env.REACT_APP_PROJECT_OLD_LINK_NAME}wallet-dashboard`,
    icon: "wallet",
    text: "Silo Wallet",
  },
  {
    link: `https://accounts.silocloud.io/`,
    icon: "setting-alt",
    text: "Account Settings",
  },
];
